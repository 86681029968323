import { Suspense, lazy, ElementType } from 'react';
// components
import LoadingScreen from '../components/loading-screen';

// ----------------------------------------------------------------------

const Loadable = (Component: ElementType) => (props: any) =>
  (
    <Suspense fallback={<LoadingScreen />}>
      <Component {...props} />
    </Suspense>
  );

// ----------------------------------------------------------------------

export const ResetPasswordPage = Loadable(lazy(() => import('../pages/auth/reset-password')));

export const LoginPage = Loadable(lazy(() => import('../pages/auth/login')));

export const Chat = Loadable(lazy(() => import('../pages/chat/index')));

export const Users = Loadable(lazy(() => import('../pages/users/index')));

export const Upload = Loadable(lazy(() => import('../pages/upload/index')));

export const Page404 = Loadable(lazy(() => import('../pages/Page404')));
