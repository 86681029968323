import { Navigate, useRoutes } from 'react-router-dom';
import ResetPasswordPage from 'src/pages/auth/reset-password';
import NotificationSetup from 'src/pages/SettingsPage/components/NotificationSetup';

import ParameterSetup from 'src/pages/SettingsPage/components/ParameterSetup';
import Campaigns from 'src/pages/SettingsPage/components/Campaigns';

// auth

import GetPhoneNumber from 'src/pages/auth/get-phone-number';
import VerifyCodePage from 'src/pages/auth/verify-otp';
import ChannelSetup from 'src/pages/SettingsPage/components/ChannelSetup';
import SettingSetup from 'src/pages/SettingsPage/components/SettingSetup';
import IgnoredContacts from 'src/pages/ignoredUsers';
import Train from 'src/pages/SettingsPage/components/Train';
import SubstitutionParameters from 'src/pages/SettingsPage/SubstitutionParameters';
import FaqSetup from 'src/pages/faqSetup';
import WebFormSettings from 'src/pages/webFormSettings';
import AuthGuard from '../auth/AuthGuard';
import GuestGuard from '../auth/GuestGuard';
// layouts
import CompactLayout from '../layouts/compact';
import DashboardLayout from '../layouts/dashboard';
// config
import { PATH_AFTER_LOGIN } from '../config-global';
//
import { Page404, Chat, LoginPage, Upload, Users } from './elements';

// ----------------------------------------------------------------------

export default function Router() {
  return useRoutes([
    {
      path: '/',
      children: [
        { element: <Navigate to={PATH_AFTER_LOGIN} replace />, index: true },
        {
          path: 'login',
          element: (
            // <GuestGuard>
            <LoginPage />
            // </GuestGuard>
          ),
        },
        {
          path: '/forgotPassword',
          element: (
            <GuestGuard>
              <ResetPasswordPage />
            </GuestGuard>
          ),
        },
        {
          path: '/webForm',
          element: (
            <GuestGuard>
              <WebFormSettings />
            </GuestGuard>
          ),
        },
        {
          path: '/get-phone-number',
          element: <GetPhoneNumber />,
        },
        {
          path: '/verify-otp',
          element: <VerifyCodePage />,
        },
      ],
    },
    {
      path: '/dashboard',
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        { element: <Navigate to={PATH_AFTER_LOGIN} replace />, index: true },
        { path: 'chat', element: <Chat /> },
        { path: 'upload', element: <Upload /> },
        { path: 'notificationsetup', element: <NotificationSetup /> },
        { path: 'train', element: <Train /> },
        { path: 'train/uploadTrainingFiles', element: <Train /> },
        { path: 'setup', element: <Campaigns /> },
        { path: 'setup/conversations', element: <Campaigns /> },
        { path: 'setup/personalizationVariables', element: <SubstitutionParameters /> },
        { path: 'setup/notificationChannel', element: <ChannelSetup /> },
        { path: 'setup/twilioService', element: <SettingSetup /> },
        { path: 'setup/parametersetup', element: <ParameterSetup /> },
        { path: 'setup/users', element: <Users /> },
        { path: 'setup/ignoredContacts', element: <IgnoredContacts /> },
        { path: 'train/faq', element: <FaqSetup /> },
      ],
    },

    {
      element: <CompactLayout />,
      children: [{ path: '404', element: <Page404 /> }],
    },
    { path: '*', element: <Navigate to="/404" replace /> },
  ]);
}
